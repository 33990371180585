import * as React from 'react';

function ArrowLeft(): JSX.Element {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4441 6.34375C15.4441 6.0625 15.3425 5.82292 15.1394 5.625L10.0222 0.515625C9.91284 0.40625 9.79826 0.328125 9.67847 0.28125C9.56388 0.229167 9.4467 0.203125 9.3269 0.203125C9.04045 0.203125 8.80868 0.291667 8.63159 0.46875C8.45451 0.645833 8.36597 0.864583 8.36597 1.125C8.36597 1.26562 8.39201 1.39583 8.44409 1.51562C8.50138 1.63021 8.5743 1.73177 8.66284 1.82031L10.4128 3.58594L13.1863 6.13281L13.5457 5.53125L10.7019 5.375H1.48315C1.18628 5.375 0.946696 5.46615 0.764404 5.64844C0.582113 5.82552 0.490967 6.05729 0.490967 6.34375C0.490967 6.63542 0.582113 6.8724 0.764404 7.05469C0.946696 7.23177 1.18628 7.32031 1.48315 7.32031H10.7019L13.5457 7.15625L13.1863 6.57031L10.4128 9.10156L8.66284 10.8672C8.5743 10.9557 8.50138 11.0573 8.44409 11.1719C8.39201 11.2865 8.36597 11.4167 8.36597 11.5625C8.36597 11.8229 8.45451 12.0417 8.63159 12.2188C8.80868 12.3958 9.04045 12.4844 9.3269 12.4844C9.5769 12.4844 9.80347 12.3828 10.0066 12.1797L15.1394 7.0625C15.3425 6.86458 15.4441 6.625 15.4441 6.34375Z"
                fill="#191D20"
            />
        </svg>
    );
}

export { ArrowLeft };
