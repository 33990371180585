import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Props } from './types';

const theme = {
    colors: {
        white: '#FEFFFF',
        darkGray: '#4F566B',
        black: '#191D20',
        red: '#FF0000',
    },
    fontSizes: {
        header: '112px',
        subHeader: '60px',
        subHeader2: '28px',
        subHeader3: '24px',
        subHeader4: '20px',
        body: '16px',
        body2: '14px',
        caption: '12px',
    },
};

function Theme({ children }: Props): JSX.Element {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export { Theme };
