import styled from 'styled-components';

const Container = styled.div`
    padding-top: 90px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 280px;
    }
`;

const Count = styled.h1`
    padding-top: 4px;
    font-weight: 700;
    font-size: 42px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 112px;
    }
`;

const Header = styled.h2`
    color: #4f566b;
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const Subheader = styled.p`
    padding-top: 4px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 28px;
    }
`;

export { Container, Count, Subheader, Header };
