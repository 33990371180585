import { Link } from 'gatsby';
import * as React from 'react';
import {
    Button,
    ButtonIcon,
    Container,
    Logo,
    Nav,
    NavItem,
    NavLink,
    NavList,
} from './styled';
import { ArrowLeft } from '../ArrowLeft';

function Header(): JSX.Element {
    return (
        <Container>
            <Link to="/">
                <Logo src="/logo.png" />
            </Link>
            <Nav>
                <NavList>
                    <NavItem>
                        <NavLink>
                            <Link to="/docs">Docs</Link>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <Button href="https://apps.apple.com/app/hooonk/id1660310099">
                            Get the app
                            <ButtonIcon>
                                <ArrowLeft />
                            </ButtonIcon>
                        </Button>
                    </NavItem>
                </NavList>
            </Nav>
        </Container>
    );
}

export { Header };
