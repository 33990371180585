import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Head = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    description
                    siteUrl
                    title
                }
            }
        }
    `);
    return (
        <>
            <Helmet title="Hooonk | Never miss a thing." />
            <Helmet>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
                <meta property="og:locale" content="en_US" />
                <meta
                    property="og:site_name"
                    content={data.site.siteMetadata.title}
                />
                <meta
                    property="og:title"
                    content="Hooonk | Never miss a thing."
                />
                <meta
                    property="og:url"
                    content={data.site.siteMetadata.siteUrl}
                />
                <meta
                    property="og:description"
                    content={data.site.siteMetadata.description}
                />
                <meta
                    property="og:image"
                    content={`${data.site.siteMetadata.siteUrl}/og.jpg?q=0`}
                />
                <meta property="og:type" content="website" />
            </Helmet>
        </>
    );
};

export { Head };
