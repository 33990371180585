import * as React from 'react';
import { ArrowLeft } from '../ArrowLeft';
import {
    Button,
    ButtonIcon,
    ButtonWrapper,
    Container,
    Florian,
    FlorianHandler,
    FlorianMemoji,
    Header,
    Serin,
    SerinHandler,
    SerinMemoji,
    TeamWrapper,
} from './styled';

function Team(): JSX.Element {
    return (
        <Container>
            <Header>Made with ❤️ in Seoul</Header>
            <TeamWrapper>
                <Florian>
                    <div>
                        <FlorianMemoji src="/florian.jpg" />
                    </div>
                    <FlorianHandler
                        href="https://florianldt.com"
                        target="_blank"
                    >
                        @florianldt
                    </FlorianHandler>
                </Florian>
                <Serin>
                    <div>
                        <SerinMemoji src="/serin.jpg" />
                    </div>
                    <SerinHandler
                        href="https://www.linkedin.com/in/serin-heo/"
                        target="_blank"
                    >
                        @serin_Heo
                    </SerinHandler>
                </Serin>
            </TeamWrapper>
            <ButtonWrapper>
                <Button href="mailto:support@hooonk.com">
                    Get in touch
                    <ButtonIcon>
                        <ArrowLeft />
                    </ButtonIcon>
                </Button>
            </ButtonWrapper>
        </Container>
    );
}

export { Team };
