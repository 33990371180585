import * as React from 'react';
import { Container, Header, Image } from './styled';

function Solution(): JSX.Element {
    return (
        <Container>
            <Header>Receive reliable and timely alerts in no time</Header>
            <Image src="/solution.jpg" />
        </Container>
    );
}

export { Solution };
