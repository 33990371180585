import * as React from 'react';
import { AppStoreImg, Container, Header } from './styled';

function Cta(): JSX.Element {
    return (
        <Container>
            <Header>You Hooonk it, We goose it!</Header>
            <a href="https://apps.apple.com/app/hooonk/id1660310099">
                <AppStoreImg src="/app_store.png" />
            </a>
        </Container>
    );
}

export { Cta };
