import * as React from 'react';
import { Link } from 'gatsby';
import {
    Container,
    Copyright,
    CopyrightWrapper,
    InnerContainer,
    LegalWrapper,
    Logo,
    Policy,
    Terms,
} from './styled';

function Footer(): JSX.Element {
    return (
        <Container>
            <InnerContainer>
                <CopyrightWrapper>
                    <Copyright>
                        Copyright ©️ 2023 Hooonk. All rights reserved.
                    </Copyright>
                </CopyrightWrapper>
                <LegalWrapper>
                    <Logo src="/hooonk_logo.png" />
                    <Policy>
                        <Link to="/privacy">Privacy Policy</Link>
                    </Policy>
                    <Terms>
                        <Link to="/terms">Terms & Conditions</Link>
                    </Terms>
                </LegalWrapper>
            </InnerContainer>
        </Container>
    );
}

export { Footer };
