import styled from 'styled-components';

const Button = styled.a`
    padding: 6px 18px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 3px solid black;
    border-radius: 100px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
`;

const ButtonIcon = styled.span`
    padding-top: 2px;
    padding-left: 4px;
    display: flex;
    align-items: center;
`;

const Container = styled.header`
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        padding: 34px 20px;
    }
`;

const Logo = styled.img`
    width: 106px;
    background-color: gray;

    @media (min-width: 768px) {
        display: flex;
    }
`;

const Nav = styled.nav``;

const NavLink = styled.span`
    font-weight: 600;
    font-size: 16px;
`;

const NavList = styled.ul`
    display: flex;
    align-items: center;
`;

const NavItem = styled.li`
    padding-left: 34px;
    display: none;
    align-items: center;

    &:last-child {
        padding-left: 48px;
        display: flex;
    }

    @media (min-width: 768px) {
        display: flex;
    }
`;

export { Button, ButtonIcon, Container, Logo, Nav, NavItem, NavLink, NavList };
