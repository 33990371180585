import styled from 'styled-components';

const Button = styled.a`
    margin: 0 auto;
    padding: 6px 18px;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: 3px solid black;
    border-radius: 100px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;

    @media (min-width: 768px) {
        padding: 14px 36px;
        font-size: 24px;
    }
`;

const ButtonIcon = styled.span`
    padding-top: 2px;
    padding-left: 4px;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding-left: 10px;
    }
`;

const ButtonWrapper = styled.div`
    padding-top: 70px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 84px;
    }
`;

const Container = styled.div`
    padding-top: 104px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 254px;
    }
`;

const Florian = styled.div`
    margin-left: auto;
`;

const FlorianHandler = styled.a`
    font-family: 'Menlo';
    font-size: 16px;
`;

const FlorianMemoji = styled.img`
    width: 204px;
    margin-left: 16px;
    padding-top: 66px;

    @media (min-width: 768px) {
        width: 266px;
        padding-top: 0;
    }
`;

const Header = styled.h1`
    max-width: 350px;
    margin: 0 auto;
    padding-top: 4px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 100%;
        font-size: 60px;
        line-height: 73px;
    }
`;

const Serin = styled.div`
    margin-right: auto;
`;

const SerinHandler = styled(FlorianHandler)``;

const SerinMemoji = styled(FlorianMemoji)`
    padding-top: 20px;

    @media (min-width: 768px) {
        padding-top: 0;
    }
`;

const TeamWrapper = styled.div`
    @media (min-width: 768px) {
        padding-top: 68px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        justify-items: center;
    }
`;

export {
    Button,
    ButtonIcon,
    ButtonWrapper,
    Container,
    Florian,
    FlorianHandler,
    FlorianMemoji,
    Header,
    Serin,
    SerinHandler,
    SerinMemoji,
    TeamWrapper,
};
