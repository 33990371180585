import styled from 'styled-components';

const AppStoreImg = styled.img`
    width: 152px;
    margin-top: 84px;

    @media (min-width: 768px) {
        width: 186px;
        margin-top: 94px;
    }
`;

const Container = styled.div`
    padding-top: 124px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 250px;
    }
`;

const Header = styled.h1`
    max-width: 202px;
    margin: 0 auto;
    padding-top: 4px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 424px;
        font-size: 60px;
        line-height: 73px;
    }
`;

export { AppStoreImg, Container, Header };
