import styled from 'styled-components';

const Container = styled.div`
    padding-top: 48px;
    color: #191d20;
    font-size: 14px;

    @media (min-width: 768px) {
        padding-top: 82px;
    }

    h1,
    h2,
    h3,
    h4 {
        font-weight: 400;
    }

    h2,
    h3,
    h4,
    h5,
    p {
        margin-bottom: 25px;
        padding: 0;
        color: #191d20;
    }

    h1 {
        margin-bottom: 26px;
        font-size: 300%;
        padding: 0px;
        font-weight: 700;
    }

    h2 {
        font-size: 150%;
        font-weight: 600;
        margin: 24px 0 16px;
    }

    h3 {
        font-size: 120%;
        font-weight: 600;
        margin-bottom: 10px;
        margin: 24px 0 16px;
    }

    h4 {
        font-size: 100%;
        font-weight: 600;
        margin: 24px 0 16px;
    }

    h5 {
        font-size: 80%;
    }

    h6 {
        font-size: 80%;
    }

    a {
        margin: 0;
        padding: 0;
        vertical-align: baseline;
    }

    ul,
    ol {
        padding: 0;
        margin: 0px 0px 16px 32px;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }

    li {
        line-height: 150%;
    }

    li ul,
    li ul {
        margin-left: 24px;
    }

    code {
        padding: 3px;
        font-family: Menlo, Consolas, Monaco, Andale Mono, monospace;
        line-height: 1.5;
        font-size: 13px;
        background: #f6f9fc;
        border-radius: 3px;
        border: 1px solid #dce6e9;
    }

    aside {
        display: block;
        float: right;
        width: 390px;
    }

    blockquote {
        border-left: 0.5em solid #eee;
        padding: 0 1em;
        margin-left: 0;
        max-width: 476px;
    }

    blockquote cite {
        font-size: 14px;
        line-height: 20px;
        color: #bfbfbf;
    }

    blockquote p {
        color: #666;
        max-width: 460px;
    }

    hr {
        height: 1px;
        padding: 0;
        margin: 24px 0;
        background-color: #bfbfbf;
        border: 0;
    }
`;

export { Container };
