import styled from 'styled-components';

const Button = styled.div`
    margin: 0 auto;
    padding: 6px 18px;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: 3px solid black;
    border-radius: 100px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;

    @media (min-width: 768px) {
        padding: 14px 36px;
        font-size: 24px;
    }
`;

const ButtonIcon = styled.span`
    padding-top: 2px;
    padding-left: 4px;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding-left: 10px;
    }
`;

const ButtonWrapper = styled.div`
    padding-top: 84px;
    text-align: center;
`;

const Container = styled.div`
    padding-top: 114px;

    @media (min-width: 768px) {
        padding-top: 214px;
    }
`;

const Header = styled.h1`
    max-width: 350px;
    margin: 0 auto;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 876px;
        font-size: 60px;
        line-height: 73px;
    }
`;

const ItemDescription = styled.p`
    padding-top: 20px;
    color: #4f566b;
    font-size: 14px;
    line-height: 22px;
`;

const ItemHeader = styled.h4`
    color: #4f566b;
    font-weight: 600;
    font-size: 18px;

    @media (min-width: 768px) {
        font-size: 20px;
    }
`;

const List = styled.ul`
    padding-top: 14px;

    @media (min-width: 768px) {
        padding-top: 108px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
    }
`;

const ListItem = styled.li`
    padding-top: 44px;

    @media (min-width: 768px) {
        padding-top: 0;
    }
`;

export {
    Button,
    ButtonIcon,
    ButtonWrapper,
    Container,
    Header,
    ItemDescription,
    ItemHeader,
    List,
    ListItem,
};
