import styled from 'styled-components';

const AppStoreImg = styled.img`
    width: 152px;
    margin-top: 36px;

    @media (min-width: 768px) {
        width: 186px;
        margin-top: 68px;
    }
`;

const Container = styled.div`
    padding-top: 48px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 148px;
    }
`;

const Header = styled.h1`
    max-width: 350px;
    margin: 0 auto;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 100%;
        font-size: 112px;
        line-height: 136px;
    }
`;

const Subheader = styled.h5`
    max-width: 350px;
    margin: 0 auto;
    padding-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 100%;
        padding-top: 44px;
        font-size: 24px;
        line-height: 29px;
    }
`;

const Subheader2 = styled(Subheader)`
    padding-top: 12px;

    @media (min-width: 768px) {
        padding-top: 20px;
    }
`;

export { AppStoreImg, Container, Header, Subheader, Subheader2 };
