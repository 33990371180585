import * as React from 'react';
import { Container, Count, Header, Subheader } from './styled';
import {Props} from './types';

function Counter({ count }: Props): JSX.Element {
    return (
        <Container>
            <Header>So far so good</Header>
            <Count>{count}</Count>
            <Subheader>Hooonks sent</Subheader>
        </Container>
    );
}

export { Counter };
