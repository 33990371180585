import { Link } from 'gatsby';
import * as React from 'react';
import { ArrowLeft } from '../ArrowLeft';
import {
    Button,
    ButtonIcon,
    ButtonWrapper,
    Container,
    Header,
    ItemDescription,
    ItemHeader,
    List,
    ListItem,
} from './styled';
function Docs(): JSX.Element {
    return (
        <Container>
            <Header>Loved by teams and individuals</Header>
            <List>
                <ListItem>
                    <ItemHeader>Create your account</ItemHeader>
                    <ItemDescription>
                        To get started, download the Hooonk app from the App
                        Store and create your account in one tap.
                    </ItemDescription>
                </ListItem>
                <ListItem>
                    <ItemHeader>Grab your code</ItemHeader>
                    <ItemDescription>
                        Copy or share your personal code to send alerts to your
                        device(s). Invalid your current code anytime by
                        refreshing it.
                    </ItemDescription>
                </ListItem>
                <ListItem>
                    <ItemHeader>Get notified</ItemHeader>
                    <ItemDescription>
                        Integrate the Hooonk API anywhere to start receiving
                        your first Hooonk. Dedicated language SDKs are on their
                        way.
                    </ItemDescription>
                </ListItem>
            </List>
            <ButtonWrapper>
                <Link to="/docs">
                    <Button>
                        Read the docs
                        <ButtonIcon>
                            <ArrowLeft />
                        </ButtonIcon>
                    </Button>
                </Link>
            </ButtonWrapper>
        </Container>
    );
}

export { Docs };
