import * as React from 'react';
import {
    AppStoreImg,
    Container,
    Header,
    Subheader,
    Subheader2,
} from './styled';

function Top(): JSX.Element {
    return (
        <Container>
            <Header>One app, never miss a thing</Header>
            <Subheader>
                Stay on top of important updates with personalized alerts.
            </Subheader>
            <Subheader2>Get the app now</Subheader2>
            <a href="https://apps.apple.com/app/hooonk/id1660310099">
                <AppStoreImg src="/app_store.png" />
            </a>
        </Container>
    );
}

export { Top };
