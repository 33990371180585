import styled from 'styled-components';

const Container = styled.footer`
    max-width: 1000px;
    margin: 0 auto;
    padding: 106px 20px 40px;

    @media (min-width: 768px) {
        padding: 242px 20px 40px;
    }
`;

const Copyright = styled.p`
    font-size: 12px;
    color: #4f566b;
`;

const CopyrightWrapper = styled.div``;

const InnerContainer = styled.div`
    position: relative;
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }
`;

const LegalWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding-top: 0;
        padding-left: 24px;
    }
`;

const Logo = styled.img`
    top: 24px;
    right: 0;
    width: 28px;
    position: absolute;
    opacity: 0.28;

    @media (min-width: 768px) {
        top: -12px;
    }
`;

const Terms = styled(Copyright)``;

const Policy = styled(Terms)`
    margin-right: 10px;
    padding-right: 10px;
    border-right: solid 1px #4f566b;
`;

export {
    Container,
    Copyright,
    CopyrightWrapper,
    InnerContainer,
    LegalWrapper,
    Logo,
    Policy,
    Terms,
};
