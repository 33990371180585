import styled from 'styled-components';

const Container = styled.div`
    padding-top: 104px;
    text-align: center;

    @media (min-width: 768px) {
        padding-top: 254px;
    }
`;

const Header = styled.h1`
    max-width: 350px;
    margin: 0 auto;
    padding-top: 4px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    @media (min-width: 768px) {
        max-width: 100%;
        font-size: 60px;
        line-height: 73px;
    }
`;

const Image = styled.img`
    width: 284px;
    padding-top: 66px;
    text-align: center;

    @media (min-width: 768px) {
        width: 512px;
        padding-top: 92px;
    }
`;

export { Container, Header, Image };
