import styled from 'styled-components';

const Pre = styled.div`
    border-radius: 3px;
    border: 1px solid #dce6e9;
    padding: 1em;
    overflow: auto;

    background: #f6f9fc;
    margin: 0 0 0.75rem;
    text-align: left;

    & .token-line span {
    font-size: 14px;
    line-height: 22px;
    font-family: Menlo, Consolas, Monaco, Andale !important;
    text-shadow: none;
        line-height: 1.3em;
        height: 1.3em;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    tab-size: 4;
    hyphens: none;
    }
`;

export { Pre };
