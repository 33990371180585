import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Footer, Head, Header, Theme } from '..';
import { Props } from './types';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Inter', 'sans-serif', 'Roboto';
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const Container = styled.main`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
`;

function Layout({ children }: Props): JSX.Element {
    return (
        <Theme>
            <React.Fragment>
                <GlobalStyle />
                <Head />
                <Header />
                <Container>{children}</Container>
                <Footer />
            </React.Fragment>
        </Theme>
    );
}

export { Layout };
