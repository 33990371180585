import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';
import React from 'react';
import { Pre } from './styled';
import {Props} from './types';

function PrismSyntaxHighlight({ children, className }: Props) {
    const language: any = className.replace(/language-/gm, '');

    return (
        <Highlight
            {...defaultProps}
            theme={theme}
            code={children}
            language={language}
        >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <Pre className={className} style={style}>
                    {tokens.slice(0, -1).map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </Pre>
            )}
        </Highlight>
    );
}

export { PrismSyntaxHighlight };
